/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "ngx-bootstrap/datepicker/bs-datepicker";

/* Importing ng-select */
@import "@ng-select/ng-select/themes/default.theme.css";

// @import "flag-icons/css/flag-icons.min.css";

/* Add Material Symbols Outlined locally */
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  src: url(/assets/materialsymboloutlined/MaterialSymbolsOutlined[FILL,GRAD,opsz,wght].woff2)
    format("woff");
}

/* Add extra CSS for material-symbols-outlined
https://developers.google.com/fonts/docs/material_symbols
*/
.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}

:root {
  --site-testing-primary: #001224;
  --site-testing-secondary: #002a55;
  --site-accent: #f6b83b;
  --site-accent-hover: #f6b83b30;
  --site-primary: #05669e;
  --site-secondary: #007fc7;
  --site-grey: #6b7f8e;

  --bs-border-radius: 12px;
}

// Disable smooth scrolling for angular routing scrollPositionRestoration: "enabled"
html {
  scroll-behavior: auto !important;
}

body {
  margin: 0 !important;
  background-color: #e8edf5;
}

html,
body {
  height: 100% !important;
}

.pointer,
.cursor-pointer {
  cursor: pointer !important;
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
  white-space: nowrap;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999999 !important;
  opacity: 1; /* Firefox */
}

.flex {
  display: flex;
}

.overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.overflow-hidden {
    overflow: hidden;
    white-space: nowrap;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999999 !important;
    opacity: 1; /* Firefox */
}

.flex {
    display: flex;
}

.h-100 {
  height: 100% !important;
}

.w-70 {
  width: 70% !important;
}

.mw-100 {
  max-width: 100px !important;
}

.mt-10 {
  margin-top: 10rem !important
}

@media only screen and (min-width: 992px) {
  .mt-lg-6 {
    margin-top: 6rem !important;
  }
  .mt-lg-8 {
    margin-top: 8rem !important;
  }
}

.mw-100 {
    max-width: 100px !important;
}

.mt-10{
    margin-top: 10rem;
}

@media only screen and (min-width: 992px) {
    .mt-lg-6 { margin-top: 6rem; }
    .mt-lg-8 { margin-top: 8rem; }
}

// font-weight
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}

// font colours
.text-accent {
  color: var(--site-accent) !important;
}
.text-primary {
  color: var(--site-primary) !important;
}
.text-secondary {
  color: var(--site-secondary) !important;
}

// buttons
.btn-primary {
  background-color: var(--site-primary);
  border-color: var(--site-primary);
}
.btn-primary:hover {
  background-color: var(--site-primary);
  border-color: var(--site-primary);
  opacity: 0.6;
}
.btn-primary:disabled {
  background-color: var(--site-primary);
  border-color: var(--site-primary);
  opacity: 0.2;
}

.btn-outline-primary {
  --bs-btn-color: var(--site-primary);
  --bs-btn-border-color: var(--site-primary);
  --bs-btn-hover-bg: var(--site-primary);
  --bs-btn-hover-border-color: var(--site-primary);
  --bs-btn-active-bg: var(--site-primary);
  --bs-btn-active-border-color: var(--site-primary);
  --bs-btn-disabled-color: var(--site-primary);
  --bs-btn-disabled-border-color: var(--site-primary);
}

.filter-btn {
  background-color: var(--site-primary);
}
.remove-btn {
  background-color: var(--bs-danger);
}
.photo-btn {
  background-color: var(--site-primary);
}
.copy-btn {
  background-color: var(--bs-teal);
}
.download-btn {
  background-color: var(--bs-teal);
}
.download-btn:hover {
  color: white;
}

.filter-btn,
.remove-btn,
.copy-btn,
.download-btn {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  padding-bottom: 0.26rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.image-preview-btn {
  height: 50px;
  width: 50px;
  border-radius: var(--bs-border-radius);
}

.no-image {
  background-color: #b9d1f0;
}

.filter-btn {
  width: 2.5rem;
  height: 2.5rem;
  opacity: 1;
}

.filter-btn:hover {
  opacity: 0.5;
}

.remove-btn:hover,
.copy-btn:hover,
.photo-btn:hover,
.download-btn:hover {
  opacity: 0.5;
}

// links
.link {
  cursor: pointer;
  opacity: 0.6;
}

.link:hover {
  color: var(--site-secondary) !important;
}

// modals
.modal-content {
  border-radius: var(--bs-border-radius) !important;
  border: none;
}

.modal-header {
  justify-content: space-between;
}

// tables
.table tbody {
  background-color: white !important;
}

.table {
  .image-container {
    width: 75px;
    height: 75px;

    .no-image {
      height: inherit;
      width: inherit;
      background-color: #b9d1f0;
    }

    img {
      height: inherit;
      width: inherit;
      object-fit: cover;
    }
  }
}

.flex-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

// ng-select
.position-relative .ng-select-container {
  height: 58px !important;
  border-radius: 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: unset;
}

.border-danger .ng-select-container {
  border-color: #dc3545;
}

// forms
.position-relative .form-label {
  margin-top: -10px;
  margin-left: 10px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: small;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  background-color: white;
  z-index: 1002 !important; // ng-select container is set to 1001
}

.errors {
  font-size: small;
  position: absolute;
  left: 10px;
  bottom: -7px;
  padding: 0 5px;
  background-color: white;
}

.errors .error {
  color: #dc3545;
}

/* general */
.survey-taker,
.belt-image-thumbnail div
// .belt-thumbnail img,
// .no-image {
{
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  object-fit: cover;
}

.survey-taker {
  background-color: #ccc;
}

@media only screen and (min-width: 0) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .standard-padding {
    padding: 1rem;
  }
}

@media only screen and (min-width: 992px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  .standard-padding {
    padding: 2rem;
  }

  .mt-lg-10 {
    margin-top: 10rem;
  }

  .mt-lg-7 {
    margin-top: 6.5rem;
  }
}

// Temp solution to tooltip issue with ngx-bootsrap
.popover {
  position: absolute;
  top: 0;
  left: 0 #{"/* rtl:ignore */"};
}

.popover-arrow {
  position: absolute;
}

.tooltip {
  position: absolute;
}

.tooltip-arrow {
  position: absolute;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
